import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Cfsm from 'calc-fsm';

import App from './components/App';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';

const preloadedState = {
  cfsmState: {
    cfsm: new Cfsm(),
    result: 0
  }
};

ReactDOM.render(
  <Provider store={createStore(reducers, preloadedState)}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
